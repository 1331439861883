import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const TindahanPage = (props) => (
  <Gallery
    metaTitle="Tindahan | c360.ai"
    title="Tindahan"
    dashboardName="tindahan-page"
    pageComponent={props}
  />
);

export default TindahanPage;
